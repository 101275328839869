import React, {useEffect} from 'react';
import {BaseFormType} from "../../../components/form/BaseFormType";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {checkDuplicateIdAsync} from "../../../redux/account/checkDuplicateIdModule";
import {useFormContext} from "react-hook-form";
import styled from "styled-components"
import Button from "../../../components/style/button/Button";
import {ErrorInput} from "../../../components/style/input/ErrorInput";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import RedDot from "../../tank/regist/certificate/component/RedDot";

interface IdCheckProps {
    onIdCheckComplete: (isDuplicate: boolean) => void
}

function IdCheck(props: IdCheckProps) {
    const {register, errors, watch} = useFormContext()
    const {userId} = watch()
    const {
        data,
        error
    } = useSelector((state: RootState) => state.accountReducers.checkDuplicateIdReducer.checkDuplicateIdResponse)
    const dispatch = useDispatch()

    function handleClick() {
        dispatch(checkDuplicateIdAsync.request(userId))
    }

    useEffect(() => {
        if (data != null) {
            props.onIdCheckComplete(data)
        }
    }, [data])

    return (
        <IdCheckDiv>
            <FixedWeightText fontSize={"12px"} fontWeight={500}>
                <RedDot/>아이디
            </FixedWeightText>
            <Contents>
                <IdInput
                    name="userId"
                    reference={register({
                        required: true,
                        pattern: /^([A-Za-z0-9가-힣]){3,10}$/
                    })}
                    error={errors.userId?.type == "pattern"}
                    errorMessage={"아이디를 확인해주세요."}
                    placeholder={"아이디 3~10자"}
                />

                <IdCheckButton
                    onClick={handleClick}>
                    <FixedWeightText
                        fontSize={"12px"}
                        fontWeight={500}
                        color={"var(--white)"}>중복확인
                    </FixedWeightText>
                </IdCheckButton>
            </Contents>
        </IdCheckDiv>
    );
}

const IdCheckDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`

const IdCheckButton = styled(Button)`
  width: 80px;
  height: 48px;
  background-color: var(--blue);
  margin-left: 8px;
`

const Contents = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 8px;
  justify-content: space-between;
`
const IdInput = styled(ErrorInput)`
  display: flex;
  box-sizing: border-box;
  width: 332px;
  height: 48px;
`
export default IdCheck