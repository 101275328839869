import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import React, {useEffect} from "react";
import Index from "../components/layout";
import {
    LINK_BASIC,
    LINK_CID,
    LINK_COLLECT_MONEY,
    LINK_CUSTOMER,
    LINK_ETC,
    LINK_HOME,
    LINK_METER_CHECK,
    LINK_PURCHASE,
    LINK_PURCHASE_ACCOUNT,
    LINK_PURCHASE_ACCOUNT_REGISTRATION,
    LINK_PURCHASE_ACCOUNT_REGISTRATION_MODIFICATION,
    LINK_PURCHASE_MODIFICATION,
    LINK_PURCHASE_REGISTRATION,
    LINK_RETEST_MODIFICATION,
    LINK_RETEST_REGISTRATION,
    LINK_SALES,
    LINK_SALES_ACCOUNT,
    LINK_SALES_ACCOUNT_REGISTRATION,
    LINK_SIGN_IN,
    LINK_SIGN_UP,
    LINK_TANK,
    LINK_TANK_MODIFICATION,
    LINK_TANK_REGISTRATION
} from "../resources/LinkConstants";
import HomePage from "../pages/home/HomeScreen";
import LoginContainer from "../pages/login/LoginContainer";
import SignUp from "../pages/signup/SignUpContainer";
import PurchaseAccount from "../pages/purchaseaccount/PurchaseAccountContainer";
import PurchaseAccountRegistContainer from "../pages/purchaseaccount/regist/PurchaseAccountRegistContainer";
import SalesAccount from "../pages/salesaccount/SalesAccountScreen";
import SalesAccountRegist from "../pages/salesaccount/regist/SalesAccountRegistContainer";
import Sales from "../pages/sales/SalesScreen";
import PurchaseContainer from "../pages/purchase/PurchaseContainer";
import MeterCheckScreen from "../pages/metercheck/MeterCheckScreen";
import CollectMoneyScreen from "../pages/collectmoney/CollectMoneyScreen";
import CidScreen from "../pages/cid/CidScreen";
import BasicDataContainer from "../pages/basic/BasicContainer";
import TankContainer from "../pages/tank/TankContainer";
import CustomerScreen from "../pages/customer/CustomerScreen";
import EtcProductContainer from "../pages/basic/component/EtcProduct/EtcProductContainer";
import AuthRoute from "../pages/main/AuthRoute";
import PurchaseRegistContainer from "../pages/purchase/regist/PurchaseRegistContainer";
import TankReExaminationRegistContainer from "../pages/tank/regist/reexamination/TankReExaminationRegistContainer";
import TankCertificateRegistrationContainer
    from "../pages/tank/regist/certificate/TankCertificateRegistrationContainer";
import axios from "axios";
import getLocalStorageItemOrNull, {getLocalStorageJsonItemOrNull} from "../lib/getLocalStorageItemOrNull";
import {
    LOCAL_STORAGE_KEY_ERP_USER_NAME,
    LOCAL_STORAGE_KEY_IS_LOGGED_IN,
    LOCAL_STORAGE_KEY_TOKEN
} from "../resources/LocalStorageConstants";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux";
import {changeHeaderTitle} from "../redux/HeaderTitleModule";

interface RootComponentProps {

}

function Root(props: RootComponentProps) {

    const {data: erpUser} = useSelector((state: RootState) => state.accountReducers.signInReducer.signInResponse)
    const {data: refreshToken} = useSelector((state: RootState) => state.refreshTokenReducer.refreshTokenResponse)
    const dispatch = useDispatch()

    const isLoggedIn = getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_IS_LOGGED_IN)

    if (isLoggedIn) {
        const userName = getLocalStorageItemOrNull(LOCAL_STORAGE_KEY_ERP_USER_NAME)
        const erpToken = getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_TOKEN)
        axios.defaults.headers.common['Authorization'] = erpToken && `Bearer ${erpToken.accessToken}`;
        dispatch(changeHeaderTitle({userName: userName}))
    }

    useEffect(() => {
        if (erpUser) {
            axios.defaults.headers.common['Authorization'] = erpUser.token && `Bearer ${erpUser.token.accessToken}`
            localStorage.setItem(LOCAL_STORAGE_KEY_IS_LOGGED_IN, "true")
            localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, JSON.stringify(erpUser.token))
            localStorage.setItem(LOCAL_STORAGE_KEY_ERP_USER_NAME, erpUser.companyName)
            dispatch(changeHeaderTitle({userName: erpUser.companyName}))
        }

        if (refreshToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${refreshToken.accessToken}`
            localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, JSON.stringify(refreshToken))
            window.location.reload();
        }
    }, [erpUser, refreshToken])

    return (
        <BrowserRouter>
            <Index>
                <Switch>
                    <Route exact path={LINK_HOME} component={HomePage}/>
                    <Route path={LINK_SIGN_IN} component={LoginContainer}/>
                    <Route exact path={LINK_SIGN_UP} component={SignUp}/>

                    <AuthRoute exact path={LINK_PURCHASE_ACCOUNT} component={PurchaseAccount}/>
                    <AuthRoute exact path={LINK_PURCHASE_ACCOUNT_REGISTRATION_MODIFICATION}
                               component={PurchaseAccountRegistContainer}/>
                    <AuthRoute exact path={LINK_PURCHASE_ACCOUNT_REGISTRATION}
                               component={PurchaseAccountRegistContainer}/>

                    <AuthRoute path={LINK_SALES_ACCOUNT} component={SalesAccount}/>

                    <AuthRoute path={LINK_SALES_ACCOUNT_REGISTRATION} component={SalesAccountRegist}/>

                    <AuthRoute exact path={LINK_SALES} component={Sales}/>

                    <AuthRoute exact path={LINK_PURCHASE} component={PurchaseContainer}/>

                    <AuthRoute exact path={LINK_PURCHASE_MODIFICATION} component={PurchaseRegistContainer}/>
                    <AuthRoute exact path={LINK_PURCHASE_REGISTRATION} component={PurchaseRegistContainer}/>

                    <AuthRoute exact path={LINK_METER_CHECK} component={MeterCheckScreen}/>

                    <AuthRoute exact path={LINK_COLLECT_MONEY} component={CollectMoneyScreen}/>

                    <AuthRoute exact path={LINK_CID} component={CidScreen}/>

                    <AuthRoute path={LINK_BASIC} component={BasicDataContainer}/>

                    <AuthRoute exact path={LINK_TANK} component={TankContainer}/>

                    <AuthRoute exact path={LINK_TANK_MODIFICATION} component={TankCertificateRegistrationContainer}/>
                    <AuthRoute exact path={LINK_TANK_REGISTRATION} component={TankCertificateRegistrationContainer}/>

                    <AuthRoute exact path={LINK_RETEST_MODIFICATION} component={TankReExaminationRegistContainer}/>
                    <AuthRoute exact path={LINK_RETEST_REGISTRATION} component={TankReExaminationRegistContainer}/>

                    <AuthRoute exact path={LINK_CUSTOMER} component={CustomerScreen}/>

                    <AuthRoute exact path={LINK_ETC} component={EtcProductContainer}/>
                    <Redirect path="*" to={LINK_TANK}/>
                </Switch>
            </Index>
        </BrowserRouter>
    );

}

export default Root;