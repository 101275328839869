import {ValidationRules} from "react-hook-form";

export interface BaseFormType {
    name?: string
    rules?: ValidationRules
}


export const RequiredRulesWithoutValidation = {required: true}

export const RequiredRulesAndValidationBusinessNumber = {
    required: true,
    minLength: 10,
    maxLength: 10,
    pattern: /^([0-9])*$/
}

export const RequiredRulesAndValidationTelNumber = {
    required: true,
    minLength: 9,
    maxLength: 11,
    pattern: /^([0-9])*$/
}

export const RequiredRulesAndValidationTelNumber2 = {
    required: true,
    minLength: 3,
    maxLength: 4,
}

export const RequiredRulesAndValidationPhoneNumber = {
    required: true,
    minLength: 10,
    maxLength: 11,
    pattern: /^([0-9])*$/
}

export const RequiredRulesAndValidationEmail = {
    required: true,
    pattern: /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
}

export const NonRequiredRulesAndValidationTelNumber = {
    ...RequiredRulesAndValidationPhoneNumber,
    required: false
}

export const NonRequiredRulesAndValidationBusinessNumber = {
    ...RequiredRulesAndValidationBusinessNumber,
    required : false
}

export const NonRequiredRulesAndValidationEmail = {
    required: false,
    pattern: /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
}