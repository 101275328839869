import React, {useEffect} from 'react';
import styled from 'styled-components';
import ModalHeader from "../../../../components/modal/Header";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import Button from "../../../../components/style/button/Button";
import Input from "../../../../components/style/input/Input";
import Camera from "../../../../assets/images/common/ic-48-camera@3x.png"
import DefaultImage from "../../../../components/style/image/DefaultImage";
import {ItemBoxDiv} from "../ItemBox";
import {RowDiv} from "../RowDiv";
import {FormProvider, useForm} from "react-hook-form";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import HorizontalSpace from "../../../../components/HorizontalSpace";
import StringItemSelect from "../../../../components/style/select/StringItemSelect";
import {manufacturerMonth, manufacturerYear} from "../LoadModalMenuItems";
import {Document, Page} from "react-pdf";
import {TankSearchType} from "./TankCertificateLoadModalContainer";
import {CertificateSharedForm} from "../../../../api/tank/certification/getShareTankCertificateForm";
import Select from "../../../../components/style/select/Select";

interface TankCertificationLoadModalProps {
    data?: string
    form?: CertificateSharedForm
    post: () => void
    onSubmit: (data: TankSearchType) => void
    showModal: boolean
    onCloseModal: () => void
    onConfirm: () => void
}

function TankCertificateLoadModalScreen(props: TankCertificationLoadModalProps) {


    function closeModal() {
        props.onCloseModal()
    }

    const methods = useForm<TankSearchType>({
        mode: "onBlur",
        criteriaMode: 'all',
    })

    function onSubmit(data: TankSearchType) {

        const {
            passYear,
            passMonth,
            manufacturerNumber,
            manufacturerType,
            volumeType,
        } = data

        if (passYear == "") {
            alert("합격년도를 입력해주세요.")
            return;
        }

        if (passMonth == "") {
            alert("합격월을 입력해주세요.")
            return;
        }

        if (manufacturerNumber < 1) {
            alert("제조번호(3~4자리)만 입력해주세요")
            return;
        }

        if (manufacturerType < 1) {
            alert("제조사를 선택해주세요.")
            return;
        }

        if (volumeType < 1) {
            alert("기종을 선택해주세요.")
            return;
        }

        console.log(
            passYear,
            passMonth,
            manufacturerNumber,
            manufacturerType,
            volumeType);

        props.onSubmit(data)
    }

    function onPostData() {
        props.post()
    }

    const sortedYear = manufacturerYear.sort(function (a, b) {
        let nameA = a.title.toUpperCase(); // ignore upper and lowercase
        let nameB = b.title.toUpperCase(); // ignore upper and lowercase
        if (nameA > nameB) {
            return -1;
        }
        if (nameA < nameB) {
            return 1;
        }

        // 이름이 같을 경우
        return 0;
    })

    useEffect(() => {
        console.log(props.data, "test")
    }, [props.data])

    return (
        <ConfirmModal showModal={props.showModal} onCancel={closeModal}>
            <ModalDiv>
                <FormProvider {...methods}>
                    <form>
                        <ModalHeader title={"합격증 불러오기"} onCancel={closeModal}/>
                        <ContentDiv>

                            <HeaderDiv>
                                <HeaderText>
                                    합격증 조회하기
                                </HeaderText>
                            </HeaderDiv>
                            <SearchBarDiv>
                                <div>
                                    <RowDiv>
                                        <ItemBoxDiv>제조사</ItemBoxDiv>
                                        <SelectBox
                                            menuItems={props.form?.manufacturerType}
                                            name={"manufacturerType"}
                                            reference={methods.register}
                                            defaultValue={1}
                                            disabled={true}
                                        />
                                    </RowDiv>
                                    <RowDiv>
                                        <ItemBoxDiv>기종</ItemBoxDiv>
                                        <SelectBox
                                            menuItems={props.form?.volumeType}
                                            name={"volumeType"}
                                            reference={methods.register}
                                            defaultValue={4}
                                        />
                                    </RowDiv>
                                </div>
                                <SearchDateDiv>
                                    <RowDiv>
                                        <ItemBoxDiv>검사합격년월</ItemBoxDiv>
                                        <DateSelectBox
                                            menuItems={sortedYear}
                                            name={"passYear"}
                                            reference={methods.register}
                                            defaultText={"검사년도"}
                                        />
                                        <DateSelectBox
                                            menuItems={manufacturerMonth}
                                            name={"passMonth"}
                                            reference={methods.register}
                                            defaultText={"날짜"}
                                        />
                                    </RowDiv>
                                    <RowDiv>
                                        <ItemBoxDiv>제조번호</ItemBoxDiv>
                                        <SearchInput
                                            name={"manufacturerNumber"}
                                            ref={methods.register}
                                            type={"number"}
                                        />
                                    </RowDiv>
                                </SearchDateDiv>
                                <div>
                                    <SearchButton onClick={methods.handleSubmit(onSubmit)}  disabled={props.form?.tankUploadCount !== undefined && props.form?.tankUploadCount > 2}>
                                        <SearchText fontWeight={500}>검색</SearchText>
                                    </SearchButton>
                                </div>
                            </SearchBarDiv>
                            <AlertDiv>
                                <AlertText>
                                    정확한 제조번호, 검사합격년월을 입력해야 다운 가능합니다.<br/>
                                    제조번호는 뒤 숫자 4자리만 입력해주세요<br/>
                                    (EX. . BLK 0.2T(V) – 1234 의 경우 1234 입력)
                                </AlertText>
                            </AlertDiv>

                            <HorizontalParent>
                                <HorizontalSpace/>
                            </HorizontalParent>

                            <ImgBoxDiv>
                                {props.data &&
                                <PdfDiv>
                                    <Document file={props.data}>
                                        <Page pageNumber={1} width={800}/>
                                    </Document>
                                </PdfDiv>
                                }
                                {!props.data &&
                                <ImgBox>
                                    <DefaultImage src={Camera} width={"48px"} height={"48px"}/>
                                </ImgBox>
                                }
                                <InfoDiv>
                                    <InfoText>1일 3회까지 불러오기 가능합니다. (24시 기준)</InfoText>
                                </InfoDiv>
                            </ImgBoxDiv>

                            <HorizontalParent>
                                <HorizontalSpace/>
                            </HorizontalParent>

                            <FooterDiv>
                                <LoadButton onClick={onPostData}
                                            disabled={props.form?.tankUploadCount !== undefined && props.form?.tankUploadCount > 2}>
                                    <SearchText fontWeight={500}>불러오기({props.form?.tankUploadCount}/3)</SearchText>
                                </LoadButton>
                            </FooterDiv>
                            <FooterDiv>
                                {props.form?.tankUploadCount && props.form?.tankUploadCount > 2 &&
                                <div>
                                    <InfoText>
                                        불러오기 횟수를 초과하였습니다.
                                    </InfoText>
                                </div>
                                }
                            </FooterDiv>

                        </ContentDiv>
                    </form>
                </FormProvider>
            </ModalDiv>
        </ConfirmModal>
    );
}

const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 838px;
  height: 774px;
  border-radius: 4px;
  background-color: var(--white);
`

const SearchBarDiv = styled(RowDiv)`
  flex: 1;
`

const SearchDateDiv = styled.div`
  margin-left: 16px;
`
const ContentDiv = styled.div`
  width: 838px;
  height: 726px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 32px;
`
const HorizontalParent = styled.div`
  width: 806px;
`
const HeaderDiv = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
`
const HeaderText = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: 400;
`

const SelectBox = styled(Select).attrs({
    width: "307px",
    height: "32px",
    marginLeft: "8px",
})``

const DateSelectBox = styled(StringItemSelect).attrs({
    width: "120px",
    height: "32px",
    marginLeft: "8px",
})``

const SearchInput = styled(Input)`
  width: 248px;
  height: 32px;
  font-size: 10px;
  margin-left: 8px;
`

const SearchButton = styled(Button)`
  width: 43px;
  height: 72px;
  margin-left: 16px;
  background-color: var(--blue);
`

const FooterDiv = styled.div`
  display: flex;
  justify-content: center;
`

const LoadButton = styled(SearchButton)`
  width: 100px;
  height: 32px;
  margin-top: 16px;
  justify-self: flex-end;
`

const SearchText = styled(FixedWeightText)`
  width: 46px;
  height: 18px;
  font-size: 10px;
  font-weight: 300;
  text-align: center;
  color: var(--white);
`

const AlertDiv = styled.div`
  height: 48px;
  margin-top: 14px;
`

const AlertText = styled(FixedWeightText)`
  font-size: 12px;
  text-align: left;
  color: var(--red);
`

const ImgBoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ImgBox = styled.div`
  display: flex;
  width: 258px;
  height: 340px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  justify-content: center;
  align-items: center;
`
const InfoDiv = styled.div`
  margin-top: 16px;
`

const InfoText = styled(FixedWeightText)`
  width: 267px;
  height: 20px;
  font-size: 12px;
  color: var(--gray-3);
`

const PdfDiv = styled.div`
  height: 372px;
  overflow: scroll;
  border: solid 1px #e8ebec;
  background-color: #fafbfc;
  margin-left: -32px;
`
export default TankCertificateLoadModalScreen