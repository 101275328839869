import React, {ChangeEvent, useEffect, useState} from 'react'
import styled from 'styled-components'
import AddressWithButton from "./component/AddressWithButton"
import SignupModal from "./component/SignupModal"
import {FormProvider, useForm} from "react-hook-form"
import {SignUpRequest} from "../../api/signup/signUp"
import {
    RequiredRulesAndValidationBusinessNumber,
    RequiredRulesAndValidationTelNumber2,
    RequiredRulesWithoutValidation
} from "../../components/form/BaseFormType"
import IdCheck from "./component/IdCheck"
import {ErrorInput} from "../../components/style/input/ErrorInput";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import Input from "../../components/style/input/Input";
import Button from "../../components/style/button/Button";
import {useHistory} from 'react-router-dom'
import {LINK_TANK} from "../../resources/LinkConstants";
import {RowDiv} from "../tank/component/RowDiv";
import Select from "../../components/style/select/Select";
import BasicCheckBox from "../../components/style/button/BasicCheckBox";
import {SignUpForm} from "../../api/signup/getSignUpForm";
import RedDot from "../tank/regist/certificate/component/RedDot";

interface SignupScreenComponentProps {
    onConfirm: (request: SignUpRequest) => void
    loading: boolean
    form? : SignUpForm
}

type SignUpScreenData = SignUpRequest & {
    passwordConfirm?: string
    fax1 : string
    fax2 : string
    fax3 : string
}

function SignUpScreen(props: SignupScreenComponentProps) {
    const methods = useForm<SignUpScreenData>({
        mode: "onBlur",
        criteriaMode: 'all',
    })

    const history = useHistory()
    const {watch} = methods
    const {password} = watch()
    const {isValid} = methods.formState

    const [request, setRequest] = useState<SignUpScreenData>()
    const [idChecked, setIdChecked] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const [check, setCheck] = useState(false)
    const [isReadOnly, setIsReadOnly] = useState(true)

    function onSubmit(data: SignUpScreenData){
        if(data.fax1.length > 2 && data.fax2.length > 2){
            data.fax = data.fax1 + data.fax2 + data.fax3
        }

        removeEmptyField(data)
        setRequest(data)
        console.log(data)
    }


    function removeEmptyField(data : any) {
        Object.keys(data).forEach(key => {
            if(data[key] instanceof Object) {
                const emptyObjectCandidateKeys = Object.keys(data[key])
                for(let i=0; i<emptyObjectCandidateKeys.length; i++) {
                    if (data[key][emptyObjectCandidateKeys[i]] === '' || data[key][emptyObjectCandidateKeys[i]] == null) {
                        delete data[key]
                        break
                    }
                }
            }
        });

        console.log(data, "after")
    }

    function handleCheck(event: React.ChangeEvent<HTMLInputElement>){
        setCheck(event.target.checked)

        if(event.target.checked){
            methods.setValue("deliveryAddress", methods.watch().address,{
                shouldValidate : true
            })
            methods.setValue("deliveryAddressDetail", methods.watch().addressDetail)
        }
    }

    function handleIdDuplicateCheck(isDuplicate: boolean) {
        alert(isDuplicate ? "이미 사용중인 아이디 입니다." : "사용이 가능한 아이디 입니다.")
        setIdChecked(!isDuplicate)
        methods.setValue("privacyAgreement", true)
    }

    function handleSubmit() {
        if (request != null) {
            request.privacyAgreement = true
            request.serviceAgreement = true
            props.onConfirm(request)
            alert("관리자의 승인을 기다려주세요")
            history.replace(LINK_TANK)
        }
    }

    function handleDomain(event: ChangeEvent<HTMLInputElement>){
        methods.setValue("email.domain", event.target.value, {
            shouldValidate : true
        })
    }

    useEffect(()=>{
        methods.setValue("email.domain", "naver.com", {
            shouldValidate : true
        })
    },[])

    useEffect(()=>{
        if(watch("domain")){
            const number = watch("domain")
            const domain = props.form?.memberEmailDomainType.filter(it => it.id === number)
            if(domain){
                if(domain[0].title == "직접입력"){
                    setIsReadOnly(false)
                } else {
                    setIsReadOnly(true)
                    methods.setValue("email.domain", domain[0].title)
                }

            }
        }
    },[watch("domain")])

    return (
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <CenterDiv>
                    <HeaderText>
                        가스경영프로그램
                    </HeaderText>
                    <input name={"fax"} ref={methods.register} hidden={true}/>
                    <AccountInfoDiv className={"somename"}>
                        <FixedWeightText fontSize={"24px"} fontWeight={500}>계정 정보 입력</FixedWeightText>

                        <IdCheck onIdCheckComplete={handleIdDuplicateCheck}/>
                        <GuideText>
                            한글, 영문, 숫자 3~10자 만들어 주세요.
                        </GuideText>

                        <InputTitle><RedDot/>비밀번호</InputTitle>

                        <ErrorInputField
                            name="password"
                            reference={methods.register({
                                required: true,
                                pattern: /^([A-Za-z0-9$@$!%*#?&]){4,16}$/
                            })}
                            type={"password"}
                            error={methods.errors.password?.type === "pattern"}
                            errorMessage={"비밀번호를 확인해주세요."}
                            placeholder={"비밀번호(4~16자리)"}
                        />

                        <GuideText>
                            4~16자 이하로 만들어주세요.
                        </GuideText>

                        <InputTitle><RedDot/>비밀번호 확인</InputTitle>

                        <ErrorInputField
                            name="passwordConfirm"
                            reference={methods.register({
                                required: true,
                                validate: value => value === password
                            })}
                            type={"password"}
                            placeholder={"비밀번호 재입력"}
                            error={methods.errors.passwordConfirm?.type == "validate"}
                            errorMessage={"비밀번호가 일치하지 않습니다."}
                        />

                    </AccountInfoDiv>

                    <BasicInfoDiv>
                        <FixedWeightText fontSize={"24px"} fontWeight={500}>기본정보</FixedWeightText>

                        <InputTitle><RedDot/>회사명</InputTitle>

                        <InputField
                            name="companyName"
                            ref={methods.register(RequiredRulesWithoutValidation)}
                        />

                        <InputTitle><RedDot/>사업자번호</InputTitle>

                        <InputField
                            name="businessNumber"
                            ref={methods.register(RequiredRulesAndValidationBusinessNumber)}
                            error={methods.errors.businessNumber?.types?.minLength === true || methods.errors.businessNumber?.types?.maxLength === true}
                        />

                        <GuideText error={methods.errors.businessNumber?.types?.pattern === true}>
                            (-)을 제외하고 입력해주세요.
                        </GuideText>

                        <InputTitle><RedDot/>대표자</InputTitle>

                        <InputField
                            name="ownerName"
                            ref={methods.register(RequiredRulesWithoutValidation)}
                        />

                        <InputTitle><RedDot/>회사번호</InputTitle>

                        <RowDiv>
                            <TelSelectBox
                                menuItems={props.form?.memberTelLocalType}
                                defaultValue={1}
                                name={"tel.localNumber"}
                                reference={methods.register}
                            />
                            <TelField
                                name={"tel.number1"}
                                ref={methods.register}
                                type={"number"}
                            />
                            <TelField
                                name={"tel.number2"}
                                ref={methods.register}
                                type={"number"}
                            />
                        </RowDiv>

                        <MobileTitle><RedDot/>핸드폰</MobileTitle>

                        <Mobile>
                            <NameAndBodyInputField
                                name="mobile1.name"
                                ref={methods.register(RequiredRulesWithoutValidation)}
                                placeholder={"담당자명"}
                            />
                            <MobileSelectBox
                                menuItems={props.form?.memberPhoneCarrierType}
                                defaultValue={1}
                                name={"mobile1.carrier"}
                                reference={methods.register}
                            />
                            <MobileField
                                name={"mobile1.number1"}
                                ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                            />
                            <MobileField
                                name={"mobile1.number2"}
                                ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                            />
                        </Mobile>

                        <Mobile>
                            <NameAndBodyInputField
                                name={"mobile2.name"}
                                ref={methods.register}
                                placeholder={"담당자명"}
                            />
                            <MobileSelectBox
                                menuItems={props.form?.memberPhoneCarrierType}
                                defaultValue={1}
                                name={"mobile2.carrier"}
                                reference={methods.register}
                            />
                            <MobileField
                                name={"mobile2.number1"}
                                ref={methods.register}
                            />
                            <MobileField
                                name={"mobile2.number2"}
                                ref={methods.register}
                            />
                        </Mobile>

                        <Mobile>
                            <NameAndBodyInputField
                                name={"mobile3.name"}
                                ref={methods.register}
                                placeholder={"담당자명"}

                            />
                            <MobileSelectBox
                                menuItems={props.form?.memberPhoneCarrierType}
                                defaultValue={1}
                                name={"mobile3.carrier"}
                                reference={methods.register}
                            />

                            <MobileField
                                name="mobile3.number1"
                                ref={methods.register}
                            />
                            <MobileField
                                name="mobile3.number2"
                                ref={methods.register}
                            />
                        </Mobile>

                        <GuideText>
                            sms 연락받을번호(최소 1개 입력)
                        </GuideText>

                        <InputTitle>팩스번호</InputTitle>

                        <RowDiv>
                            <TelSelectBox
                                menuItems={props.form?.memberTelLocalType}
                                defaultValue={1}
                                name={"fax1"}
                                reference={methods.register}
                            />
                            <TelField
                                name={"fax2"}
                                ref={methods.register}
                            />
                            <TelField
                                name={"fax3"}
                                ref={methods.register}
                            />
                        </RowDiv>

                        <InputTitle>이메일</InputTitle>

                        <Email>
                            <MailNameInputField
                                name={"email.name"}
                                ref={methods.register}
                                placeholder={"담당자명"}
                            />
                            <MailField
                                name={"email.email"}
                                ref={methods.register(RequiredRulesWithoutValidation)}
                            />
                            <AtDiv>@</AtDiv>
                            <MailField
                                name={"email.domain"}
                                ref={methods.register(RequiredRulesWithoutValidation)}
                                readOnly={isReadOnly}
                                onChange={handleDomain}
                            />
                            <MailSelectBox
                                name={"domain"}
                                reference={methods.register}
                                menuItems={props.form?.memberEmailDomainType}
                                defaultValue={1}
                            />
                        </Email>

                        <InputTitle><RedDot/>회사주소</InputTitle>

                        <AddressWithButton
                            name={"address"}
                            rules={RequiredRulesWithoutValidation}
                        />

                        <AddressDetailInput
                            name={"addressDetail"}
                            ref={methods.register}
                            placeholder={"상세주소"}
                        />

                        <InputTitle><RedDot/>배송 주소</InputTitle>

                        <AddressWithButton
                            name={"deliveryAddress"}
                            rules={RequiredRulesWithoutValidation}
                        />

                        <AddressDetailInput
                            name={"deliveryAddressDetail"}
                            ref={methods.register}
                            placeholder={"상세주소"}
                            readOnly={check}
                        />

                        <CheckDiv>
                            <AddressCheckBox checked={check} handleChange={handleCheck}/>
                            <FixedWeightText fontSize={"12px"}>회사주소와 동일</FixedWeightText>
                        </CheckDiv>

                        <NextButton
                            onClick={() => setShowModal(true)}
                            type={"submit"}
                            disabled={!isValid}
                        >
                            <FixedWeightText fontSize={"12px"} color={"var(--white)"}>다음</FixedWeightText>
                        </NextButton>
                    </BasicInfoDiv>

                    <SignupModal showModal={showModal}
                                 onCloseModal={() => setShowModal(false)}
                                 onConfirm={handleSubmit}
                    />

                </CenterDiv>
            </form>
        </FormProvider>
    )
}

const CenterDiv = styled.div`
  display: flex;
  flex-direction : column;
  justify-content: center;
  align-items: center;
`

const AddressCheckBox = styled(BasicCheckBox)`
  padding : 0px;
  margin-left: -2px;
  border-width: 1px;
`
const AccountInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 484px;
  height: auto;
  border-radius: 4px;
  padding: 32px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  margin-top: 52px;
`

const CheckDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`
const BasicInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 484px;
  padding : 32px;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  margin-top : 48px;
  margin-bottom : 200px;
`

const HeaderText = styled(FixedWeightText).attrs({
    fontWeight: 600
})`
  font-size: 36px;
  margin-top: 64px;
`

const InputTitle = styled(FixedWeightText).attrs({
    fontSize: "12px",
    fontWeight: 500
})`
  margin-top: 24px;
  margin-bottom: 8px;
  height: 20px;
`

type GuideTextProps = {
    error?: boolean
}

const GuideText = styled(FixedWeightText).attrs({
    fontSize: "12px",
    fontWeight: "normal"
})<GuideTextProps>`
  margin-top: 8px;
  margin-left: 2px;
  height: 18px;
  color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

const MobileTitle = styled(FixedWeightText).attrs({
    fontSize: "14px",
    fontWeight: 500
})`
  margin-top: 24px;
  height: 20px;
`

const Mobile = styled.div`
  display: flex;
  margin-top : 8px;
  justify-content: space-between;
`

const MarginMobile = styled(Mobile)`
  margin-top: 8px;
`
const Email = styled(Mobile)``

type InputFieldProps = {
    error?: boolean
}

const ErrorInputFieldWithGuide = styled(Input).attrs((props: InputFieldProps) => ({
    focusBorderColor: (props.error && "var(--red)") || "var(--blue)"
}))<InputFieldProps>`
  border-color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

const InputField = styled(ErrorInputFieldWithGuide)`
  width : 420px;
  height: 48px;
`

const TelSelectBox = styled(Select).attrs({
    width: "80px",
    height: "48px",
})``

const TelField = styled(ErrorInputFieldWithGuide)`
  width: 162px;
  height: 48px;
  margin-left: 8px;
`

const MailNameInputField = styled(Input)`
  display: flex;
  width: 93.5px;
  height: 48px;
  margin-right: 8px;
`

const MailSelectBox = styled(Select).attrs({
    width: "93.5px",
    height: "48px",
})``

const MailField = styled(ErrorInputFieldWithGuide)`
  width: 93.5px;
  height: 48px;
  margin-right: 8px;
`

const AtDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`


const MobileSelectBox = styled(Select).attrs({
    width: "99px",
    height: "48px",
})``

const MobileField = styled(InputField)`
  width: 99px;
  margin-left: 8px;
`

const ErrorInputField = styled(ErrorInput)`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 420px;
  height: 48px;
`

const NameAndBodyInputField = styled(Input)`
  display: flex;
  width: 99px;
  height: 48px;
  margin-right: 8px;
`

const AddressDetailInput = styled(Input)`
 display: flex;
 box-sizing: border-box;
 width: 420px;
 height: 48px;
 margin-top : 8px;
`

const NextButton = styled(Button)`
  width: 420px;
  height: 48px;
  margin-top : 24px;
  color: var(--white);
`

export default SignUpScreen