import React from 'react';
import ErpTableComponent from "../../../components/table/ErpTable";
import {LINK_TANK_REGISTRATION} from "../../../resources/LinkConstants";
import {useHistory} from "react-router-dom";
import {ICellRendererParams} from "ag-grid-community";
import {RowSelectedEvent} from "ag-grid-community/dist/lib/events";
import {ExaminationHistory} from "../../../api/tank/certification/postCertificate";
import styled from "styled-components";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import {TankInfo} from "../../../lib/fileDownload";

interface TableExampleGridComponentProps {
    data?: Array<any>
    onRowChanged: (urls: Array<TankInfo>) => void
}

function TankListTable(props: TableExampleGridComponentProps) {
    const history = useHistory();

    function onCellClicked(uid: string) {
        history.push(LINK_TANK_REGISTRATION + `/${uid}`)
    }

    function onRowSelected(event: RowSelectedEvent) {
        const array = Array<TankInfo>()
        event.api.forEachNode((item) => {
            if (item.isSelected()) {
                array.push({data : item.data.fileUri, name : item.data.manufacturerNumber})
            }
        })

        props.onRowChanged(array)
    }

    function activeCellRenderer(data: ICellRendererParams) {
        return (<Div>{data.value?.map((item: ExaminationHistory) =>
            <Flex1><StyledText>{item.testDate}</StyledText></Flex1>)}</Div>)
    }

    return (
        <>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={576}
                onCellClicked={onCellClicked}
                rowData={props.data}
                multipleSelect={'multiple'}
                onRowSelected={onRowSelected}
                columns={[
                    {
                        id: "manufacturerNumber",
                        title: "제조번호",
                        accessor: "manufacturerNumber",
                        sortable: true,
                        clickable: true,
                        width: 101.6,
                    }, {
                        id: "manufacturer",
                        title: "제조사",
                        accessor: "manufacturer",
                        sortable: true,
                        width: 101.6,
                    }, {
                        id: "volume",
                        title: "용량",
                        accessor: "volume",
                        sortable: true,
                        width: 101.6,
                    }, {
                        id: "ownerType",
                        title: "자가여부",
                        accessor: "ownerType",
                        sortable: true,
                        width: 101.6,
                    }, {
                        id: "companyName",
                        title: "회원사명",
                        accessor: "companyName",
                        sortable: true,
                        width: 101.6,
                    }, {
                        id: "passDate",
                        title: "검사합격일",
                        accessor: "passDate",
                        sortable: true,
                        width: 101.6,
                    }, {
                        id: "nextExaminationDate",
                        title: "다음검사",
                        accessor: "nextExaminationDate",
                        sortable: true,
                        width: 101.6,
                    },
                    {
                        id: "history",
                        title: "최신검사",
                        accessor: "history",
                        sortable: false,
                        width: 303.8,
                        cellRendererFramework: activeCellRenderer
                    },
                    {
                        id: "selected",
                        title: "",
                        accessor: "selected",
                        width: 64,
                        checked: true
                    },
                ]}/>
        </>

    )
}

const Div = styled.div`
  display: flex;
  flex : 1;
  flex-direction: row;
`

const Flex1 = styled.div`
  display: flex;
  flex: 1;
  width: 101.3px;
  justify-content: center;
`

const StyledText = styled(FixedWeightText)`
  font-size: 100%;
  font-weight: 400;
`

export default TankListTable
